import React, { useState } from 'react';
import { ArrowLeft, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Fire1000 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const fires = '🔥'.repeat(1000);

  const handleCopy = () => {
    navigator.clipboard.writeText(fires);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const title = "1000 Fire Emojis 🔥 Copy and Paste [" + currentYear + "] Free Collection";
  const description = "【INSTANT COPY】 Get 1000 fire emojis! 🔥 Perfect for expressing heat & intensity 🔥 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear + " ✨ Free fire emojis for Instagram, WhatsApp & more!";
  const ogTitle = "1000 Fire Emojis 🔥 Copy and Paste [" + currentYear + "] Free Collection";
  const ogDescription = "Get 1000 fire emojis! 🔥 Perfect for expressing heat & intensity 🔥 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear;
  const twitterTitle = "1000 Fire Emojis 🔥 Copy and Paste [" + currentYear + "] Free Collection";
  const twitterDescription = "Get 1000 fire emojis! 🔥 Perfect for expressing heat & intensity 🔥 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/emoji/fire-1000" />
        
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:url" content="https://emojicollection.net/emoji/fire-1000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1544829099-b9a0c07fad1a?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1544829099-b9a0c07fad1a?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="1000 fire emojis, fire emoji copy paste, flame emoji, hot emoji, lit emoji, instagram fire, whatsapp fire" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="Emoji Collection" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <img 
                src="https://images.unsplash.com/photo-1544829099-b9a0c07fad1a?auto=format&fit=crop&w=1200&q=80"
                alt="Express intensity with fire emojis"
                className="w-full h-48 object-cover rounded-xl mb-8"
              />
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-orange-500 to-red-500 text-white mb-4">
                <span className="text-2xl">🔥</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                1000 Fire Emojis 🔥 Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to 1000 fire emojis! Perfect for expressing heat, intensity, and excitement. Each flame is carefully selected to help you express your passion. Updated for {currentYear} with the latest emojis. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gradient-to-r from-orange-50 to-red-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                  <span className="text-2xl mr-2">🔥</span>
                  Copy 1000 Fire Emojis Instantly
                </h2>
                <div className="mb-6 overflow-auto max-h-96 text-left break-words text-2xl bg-white p-6 rounded-lg shadow-inner">
                  {fires}
                </div>
                <button
                  onClick={handleCopy}
                  className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-orange-500 to-red-500 text-white hover:from-orange-600 hover:to-red-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={24} />
                      Copied 1000 Fires!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={24} />
                      Copy 1000 Fires
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">✨ Fire Emoji Uses & Meanings</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-4">Expression Types</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">🔥 Single Fire - Basic intensity</li>
                    <li className="flex items-center">🔥🔥 Double Fire - High energy</li>
                    <li className="flex items-center">🔥🔥🔥 Triple Fire - Maximum intensity</li>
                    <li className="flex items-center">🔥✨ Fire & Sparkles - Magic moments</li>
                    <li className="flex items-center">🔥💯 Fire & 100 - Perfect score</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-4">Features</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">✨ 1000 intense fires</li>
                    <li className="flex items-center">🌟 Perfect for emphasis</li>
                    <li className="flex items-center">📱 Works on all devices</li>
                    <li className="flex items-center">🔄 Fresh patterns</li>
                    <li className="flex items-center">📋 One-click copy feature</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">📱 Perfect Uses for Fire Emojis</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-2">Intensity</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Epic moments</li>
                    <li>Hot topics</li>
                    <li>Exciting news</li>
                    <li>Achievements</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Expression</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Enthusiasm</li>
                    <li>Excitement</li>
                    <li>Success</li>
                    <li>Passion</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why use fire emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Fire emojis are perfect for expressing intensity, excitement, and passion. They add energy and emphasis to your messages and social media posts.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these fires work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These fire emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Are these fires free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! Our fire emojis are completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default Fire1000;