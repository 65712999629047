import React from 'react';

const StarFAQ = () => {
  return (
    <section className="bg-white rounded-2xl shadow-lg p-8">
      <h2 className="text-2xl font-semibold mb-6">❓ Frequently Asked Questions</h2>
      <div className="space-y-4">
        <details className="group bg-gray-50 rounded-lg">
          <summary className="cursor-pointer p-4 font-medium">Why use star emojis?</summary>
          <div className="p-4 pt-0 text-gray-600">
            Star emojis are perfect for expressing excellence, achievement, and success. They add a touch of brilliance to your messages and help celebrate special accomplishments.
          </div>
        </details>
        <details className="group bg-gray-50 rounded-lg">
          <summary className="cursor-pointer p-4 font-medium">Do these stars work everywhere?</summary>
          <div className="p-4 pt-0 text-gray-600">
            Yes! These star emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
          </div>
        </details>
        <details className="group bg-gray-50 rounded-lg">
          <summary className="cursor-pointer p-4 font-medium">Are these stars free to use?</summary>
          <div className="p-4 pt-0 text-gray-600">
            Absolutely! Our star emojis are completely free to use for both personal and commercial purposes. No attribution required.
          </div>
        </details>
      </div>
    </section>
  );
};

export default StarFAQ;