import React, { useState } from 'react';
import { ArrowLeft, Heart, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Hearts100 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const hearts = [
    '❤️', '💖', '💝', '💕', '💗', '💓', '💘', '💞', '💟', '♥️'
  ];

  const heartMessage = Array(100)
    .fill(null)
    .map(() => hearts[Math.floor(Math.random() * hearts.length)])
    .join('');

  const handleCopy = () => {
    navigator.clipboard.writeText(heartMessage);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

   const title = `100 Heart Emojis Copy and Paste 💜💙 💚♥️ [${currentYear}] ♥️♥️♥️♥️`;
  const description = `【INSTANT COPY】 Get 1000 beautiful heart emojis! ❤️ All colors included 💜 💙 💚 🧡 One-click copy & paste 💝 Updated for ${currentYear} ♥️ Works on Instagram, WhatsApp, Facebook & everywhere! #1 Heart Collection`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/hearts-100" />
        
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://emojicollection.net/hearts-100" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1518199266791-5375a83190b7?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1518199266791-5375a83190b7?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="100 heart emojis, heart emoji copy paste, colored hearts, love emoji, heart symbols, instagram hearts, whatsapp hearts, facebook hearts" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-pink-500 to-red-500 text-white mb-4">
                <Heart className="w-8 h-8" />
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                100 Heart Emojis ❤️ Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to 100 beautiful heart emojis! Perfect for expressing love and affection. Each heart is carefully selected to create a stunning display of love. Updated for {currentYear} with the latest emojis. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gradient-to-r from-pink-50 to-red-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                  <Heart className="w-6 h-6 text-pink-500 mr-2" />
                  Copy 100 Hearts Instantly
                </h2>
                <div className="mb-6 overflow-auto max-h-96 text-left break-words text-2xl bg-white p-6 rounded-lg shadow-inner">
                  {heartMessage}
                </div>
                <button
                  onClick={handleCopy}
                  className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-pink-500 to-red-500 text-white hover:from-pink-600 hover:to-red-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={24} />
                      Copied 100 Hearts!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={24} />
                      Copy 100 Hearts
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Express Love with Hearts</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-4">Heart Colors & Meanings</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">❤️ Red Heart - Classic love & passion</li>
                    <li className="flex items-center">💜 Purple Heart - Luxury & royalty</li>
                    <li className="flex items-center">💙 Blue Heart - Trust & loyalty</li>
                    <li className="flex items-center">💚 Green Heart - Nature & growth</li>
                    <li className="flex items-center">🧡 Orange Heart - Energy & enthusiasm</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-4">Special Hearts</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">💖 Sparkling Heart - Excitement & joy</li>
                    <li className="flex items-center">💝 Heart with Ribbon - Special gift of love</li>
                    <li className="flex items-center">💗 Growing Heart - Increasing affection</li>
                    <li className="flex items-center">💓 Beating Heart - Life & vitality</li>
                    <li className="flex items-center">💘 Heart with Arrow - Love at first sight</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Perfect For Every Occasion</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-3">Romance</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Love messages</li>
                    <li>Valentine's Day</li>
                    <li>Anniversaries</li>
                    <li>Date nights</li>
                    <li>Wedding content</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-3">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                    <li>YouTube comments</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-3">Messaging Apps</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>WhatsApp chats</li>
                    <li>Messenger texts</li>
                    <li>iMessage</li>
                    <li>Telegram</li>
                    <li>SMS messages</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Platform Compatibility</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">Mobile Devices</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>✓ iPhone & iPad (iOS)</li>
                    <li>✓ Android phones & tablets</li>
                    <li>✓ Samsung devices</li>
                    <li>✓ All mobile browsers</li>
                  </ul>
                </div>
                <div className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">Desktop Platforms</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>✓ Windows (Chrome, Firefox, Edge)</li>
                    <li>✓ macOS (Safari, Chrome)</li>
                    <li>✓ Linux systems</li>
                    <li>✓ All modern browsers</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why choose 100 hearts?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    100 hearts provide a perfect balance - enough to make a strong visual impact while being suitable for most social media posts and messages.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these hearts work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These heart emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Are these hearts free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! Our heart emojis are completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default Hearts100;