import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';
import AdLayout from './components/AdLayout';
import PrivacyBanner from './components/PrivacyBanner';

// Import pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import EmojiKeyboard from './pages/EmojiKeyboard';
import Search from './pages/Search';
import Disclaimer from './pages/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Sitemap from './pages/Sitemap';

// Import tools
import TextToEmoji from './pages/TextToEmoji';
import TextToEmojiPicture from './pages/TextToEmojiPicture';
import TextReplicator from './pages/TextReplicator';

// Import emoji pages
import Hearts5 from './pages/Hearts5';
import Hearts50 from './pages/Hearts50';
import Hearts100 from './pages/Hearts100';
import HeartEmoji1000 from './pages/HeartEmoji1000';
import Kiss1000 from './pages/Kiss1000';
import Christmas1000 from './pages/Christmas1000';
import FoodDrink from './pages/FoodDrink';
import BlueRoses1000 from './pages/BlueRoses1000';
import RoseEmoji from './pages/RoseEmoji';
import Roses1000 from './pages/Roses1000';
import Party1000 from './pages/Party1000';
import Laugh1000 from './pages/Laugh1000';
import Sad1000 from './pages/Sad1000';
import Sorry1000 from './pages/Sorry1000';
import Santa100 from './pages/Santa100';
import HappyChristmas50 from './pages/HappyChristmas50';
import ILoveYou10000 from './pages/ILoveYou10000';
import LoveLetter1000 from './pages/LoveLetter1000';
import Happy1000 from './pages/Happy1000';
import NewEmoji from './pages/NewEmoji';
import ZeroEmoji from './pages/ZeroEmoji';
import Clap1000 from './pages/Clap1000';
import Fire1000 from './pages/Fire1000';
import Star1000 from './pages/Star1000';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen flex flex-col bg-purple-50">
          <Header />
          <PrivacyBanner />
          <main className="flex-grow">
            <AdLayout>
              <Routes>
                {/* Main Pages */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/emoji-keyboard" element={<EmojiKeyboard />} />
                <Route path="/search" element={<Search />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/sitemap" element={<Sitemap />} />

                {/* Tools */}
                <Route path="/text-to-emoji" element={<TextToEmoji />} />
                <Route path="/text-to-emoji-picture" element={<TextToEmojiPicture />} />
                <Route path="/text-replicator" element={<TextReplicator />} />

                {/* Heart Collections */}
                <Route path="/hearts-5" element={<Hearts5 />} />
                <Route path="/hearts-50" element={<Hearts50 />} />
                <Route path="/hearts-100" element={<Hearts100 />} />
                <Route path="/hearts-1000" element={<HeartEmoji1000 />} />

                {/* Rose Collections */}
                <Route path="/roses-1000" element={<Roses1000 />} />
                <Route path="/rose" element={<RoseEmoji />} />
                <Route path="/blue-roses-1000" element={<BlueRoses1000 />} />

                {/* Kiss Collections */}
                <Route path="/kiss-1000" element={<Kiss1000 />} />

                {/* Other Collections */}
                <Route path="/christmas-1000" element={<Christmas1000 />} />
                <Route path="/food-drink" element={<FoodDrink />} />
                <Route path="/party-1000" element={<Party1000 />} />
                <Route path="/laugh-1000" element={<Laugh1000 />} />
                <Route path="/sad-1000" element={<Sad1000 />} />
                <Route path="/sorry-1000" element={<Sorry1000 />} />
                <Route path="/santa-100" element={<Santa100 />} />
                <Route path="/happy-christmas-50" element={<HappyChristmas50 />} />
                <Route path="/i-love-you-10000" element={<ILoveYou10000 />} />
                <Route path="/love-letter-1000" element={<LoveLetter1000 />} />

                {/* Emoji Routes */}
                <Route path="/emoji/happy-1000" element={<Happy1000 />} />
                <Route path="/new-emojis" element={<NewEmoji />} />
                <Route path="/emoji/zero" element={<ZeroEmoji />} />
                <Route path="/emoji/clap-1000" element={<Clap1000 />} />
                <Route path="/emoji/fire-1000" element={<Fire1000 />} />
                <Route path="/emoji/star-1000" element={<Star1000 />} />
              </Routes>
            </AdLayout>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;