import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useStarGenerator } from './hooks/useStarGenerator';

const StarDisplay = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const { stars } = useStarGenerator(1000);

  const handleCopy = () => {
    navigator.clipboard.writeText(stars);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <article className="bg-white rounded-2xl shadow-lg p-8 mb-8">
      <button
        onClick={() => navigate('/')}
        className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
      >
        <span className="mr-2">←</span>
        Back to Home
      </button>

      <header className="text-center mb-8">
        <img 
          src="https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&w=1200&q=80"
          alt="Express excellence with star emojis"
          className="w-full h-48 object-cover rounded-xl mb-8"
        />
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-yellow-500 to-amber-500 text-white mb-4">
          <span className="text-2xl">⭐</span>
        </div>
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          1000 Star Emojis ⭐ Copy and Paste [{new Date().getFullYear()}]
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Get instant access to 1000 star emojis! Perfect for expressing excellence, success, and achievement. Each star is carefully selected to help you shine bright.
        </p>
      </header>

      <div className="max-w-2xl mx-auto">
        <div className="bg-gradient-to-r from-yellow-50 to-amber-50 rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
            <span className="text-2xl mr-2">⭐</span>
            Copy 1000 Stars Instantly
          </h2>
          <div className="mb-6 overflow-auto max-h-96 text-left break-words text-2xl bg-white p-6 rounded-lg shadow-inner">
            {stars}
          </div>
          <button
            onClick={handleCopy}
            className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
              copied
                ? 'bg-green-500 text-white'
                : 'bg-gradient-to-r from-yellow-500 to-amber-500 text-white hover:from-yellow-600 hover:to-amber-600'
            }`}
          >
            {copied ? (
              <>
                <Check className="mr-2" size={24} />
                Copied 1000 Stars!
              </>
            ) : (
              <>
                <Copy className="mr-2" size={24} />
                Copy 1000 Stars
              </>
            )}
          </button>
        </div>
      </div>
    </article>
  );
};

export default StarDisplay;