import { useState, useEffect } from 'react';

export const useStarGenerator = (count: number) => {
  const [stars, setStars] = useState('');

  useEffect(() => {
    const starEmoji = '⭐';
    setStars(starEmoji.repeat(count));
  }, [count]);

  return { stars };
};