import React, { useState } from 'react';
import { Search, Copy, Check, History, Star, Clock, Keyboard, Smile, Heart, Flag, Utensils, Car, Music, Sun, Moon } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const EMOJI_CATEGORIES = [
  {
    name: "Frequently Used",
    icon: <Clock className="w-5 h-5" />,
    emojis: ["😊", "❤️", "👍", "🎉", "✨", "🔥", "💯", "🙌", "👏", "🎈"]
  },
  {
    name: "Smileys & People",
    icon: <Smile className="w-5 h-5" />,
    emojis: ["😀", "😃", "😄", "😁", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", "🥰"]
  },
  {
    name: "Love & Hearts",
    icon: <Heart className="w-5 h-5" />,
    emojis: ["❤️", "🧡", "💛", "💚", "💙", "💜", "🤎", "🖤", "🤍", "💔", "❤️‍🔥", "💘", "💝", "💖", "💗"]
  },
  {
    name: "Flags & Symbols",
    icon: <Flag className="w-5 h-5" />,
    emojis: ["🏁", "🚩", "🎌", "🏴", "🏳️", "🏳️‍🌈", "🏳️‍⚧️", "🏴‍☠️", "⭐", "✨", "💫", "☀️", "🌟", "⚡", "🔥"]
  },
  {
    name: "Food & Drink",
    icon: <Utensils className="w-5 h-5" />,
    emojis: ["🍕", "🍔", "🍟", "🌭", "🍿", "🧂", "🥨", "🥯", "🥖", "🥐", "🍞", "🥪", "🌮", "🌯", "🥙"]
  },
  {
    name: "Travel & Places",
    icon: <Car className="w-5 h-5" />,
    emojis: ["🚗", "✈️", "🚅", "🚢", "🏖️", "🗽", "🗼", "🗿", "🎡", "🎢", "🎪", "🏰", "🏯", "🏟️", "⛩️"]
  },
  {
    name: "Activities",
    icon: <Music className="w-5 h-5" />,
    emojis: ["⚽", "🏀", "🏈", "⚾", "🎾", "🏐", "🏉", "🎱", "🎮", "🎲", "🎭", "🎨", "🎬", "🎤", "🎧"]
  },
  {
    name: "Nature & Weather",
    icon: <Sun className="w-5 h-5" />,
    emojis: ["🌞", "🌝", "🌛", "🌜", "🌚", "🌕", "🌖", "🌗", "🌘", "🌑", "🌒", "🌓", "🌔", "⭐", "🌟"]
  }
];

const EmojiKeyboard: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [copied, setCopied] = useState<string | null>(null);
  const [recentEmojis, setRecentEmojis] = useState<string[]>([]);

  const handleCopy = (emoji: string) => {
    navigator.clipboard.writeText(emoji);
    setCopied(emoji);
    setTimeout(() => setCopied(null), 2000);
    
    setRecentEmojis(prev => {
      const newRecent = [emoji, ...prev.filter(e => e !== emoji)].slice(0, 30);
      localStorage.setItem('recentEmojis', JSON.stringify(newRecent));
      return newRecent;
    });
  };

  return (
    <>
      <Helmet>
        <title>⌨️ Ultimate Emoji Keyboard 2025 - Fast Copy & Paste 🎮 | #1 Online Emoji Picker ⚡</title>
        <meta name="description" content="✨ FASTEST emoji keyboard online! Instant copy with one click ⌨️ 2000+ emojis 🎮 Smart categorization 🚀 Recent emojis 💾 Mobile-friendly 📱 Works everywhere! Updated for 2025 ⚡" />
        <link rel="canonical" href="https://emojicollection.net/emoji-keyboard" />
        
        <meta property="og:title" content="⌨️ Ultimate Emoji Keyboard 2025 - Fast Copy & Paste 🎮 | #1 Online Emoji Picker" />
        <meta property="og:description" content="✨ FASTEST emoji keyboard online! Instant copy with one click ⌨️ 2000+ emojis 🎮 Smart categorization 🚀 Recent emojis 💾 Mobile-friendly" />
        <meta property="og:url" content="https://emojicollection.net/emoji-keyboard" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="⌨️ Ultimate Emoji Keyboard 2025 - Fast Copy & Paste 🎮 | #1 Online Emoji Picker" />
        <meta name="twitter:description" content="✨ FASTEST emoji keyboard online! Instant copy with one click ⌨️ 2000+ emojis 🎮 Smart categorization 🚀 Recent emojis" />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />

        <meta name="keywords" content="emoji keyboard 2025, online emoji keyboard, emoji picker, virtual keyboard, copy paste emoji, emoji search, keyboard emojis, mobile emoji keyboard, free emoji keyboard, instant emoji copy" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Ultimate Emoji Keyboard 2025",
            "applicationCategory": "UtilityApplication",
            "operatingSystem": "Any",
            "description": "The fastest online emoji keyboard with instant copy functionality, smart categorization, and mobile-friendly design.",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "featureList": [
              "Instant copy functionality",
              "2000+ emojis",
              "Smart categorization",
              "Recent emojis",
              "Mobile-friendly design",
              "Search functionality",
              "Multiple categories",
              "No installation required"
            ],
            "screenshot": "https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "ratingCount": "1250"
            }
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <div className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white mb-6">
                <Keyboard className="w-8 h-8" />
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Ultimate Emoji Keyboard ⌨️
              </h1>
              <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                The fastest and most user-friendly emoji keyboard online! Access 2000+ emojis instantly with our smart categorization system. Perfect for social media, messaging, and digital communication.
              </p>

              <div className="max-w-xl mx-auto mb-8">
                <div className="relative">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search emojis by name or category..."
                    className="w-full px-4 py-3 pl-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>

              {recentEmojis.length > 0 && (
                <div className="mb-8">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <History className="w-5 h-5 mr-2" />
                    Recent Emojis
                  </h2>
                  <div className="grid grid-cols-8 sm:grid-cols-10 md:grid-cols-12 lg:grid-cols-15 gap-2">
                    {recentEmojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => handleCopy(emoji)}
                        className="relative p-2 text-2xl hover:bg-gray-100 rounded transition-colors"
                      >
                        {emoji}
                        {copied === emoji && (
                          <span className="absolute inset-0 flex items-center justify-center bg-green-500 bg-opacity-90 rounded text-white text-sm">
                            <Check className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {EMOJI_CATEGORIES.map((category, index) => (
                <div key={index} className="mb-8">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    {category.icon}
                    <span className="ml-2">{category.name}</span>
                  </h2>
                  <div className="grid grid-cols-8 sm:grid-cols-10 md:grid-cols-12 lg:grid-cols-15 gap-2">
                    {category.emojis.map((emoji, emojiIndex) => (
                      <button
                        key={emojiIndex}
                        onClick={() => handleCopy(emoji)}
                        className="relative p-2 text-2xl hover:bg-gray-100 rounded transition-colors"
                      >
                        {emoji}
                        {copied === emoji && (
                          <span className="absolute inset-0 flex items-center justify-center bg-green-500 bg-opacity-90 rounded text-white text-sm">
                            <Check className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              ))}

              <section className="mt-12 border-t border-gray-200 pt-8">
                <h2 className="text-2xl font-semibold mb-6">Key Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="text-center p-4">
                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-indigo-100 text-indigo-500 mb-4">
                      <Copy className="w-6 h-6" />
                    </div>
                    <h3 className="text-lg font-medium mb-2">Instant Copy</h3>
                    <p className="text-gray-600">One-click copy functionality for instant emoji access</p>
                  </div>
                  <div className="text-center p-4">
                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-purple-100 text-purple-500 mb-4">
                      <Search className="w-6 h-6" />
                    </div>
                    <h3 className="text-lg font-medium mb-2">Smart Search</h3>
                    <p className="text-gray-600">Find any emoji quickly with our intelligent search</p>
                  </div>
                  <div className="text-center p-4">
                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-pink-100 text-pink-500 mb-4">
                      <History className="w-6 h-6" />
                    </div>
                    <h3 className="text-lg font-medium mb-2">Recent Emojis</h3>
                    <p className="text-gray-600">Quick access to your frequently used emojis</p>
                  </div>
                </div>
              </section>

              <section className="mt-12 border-t border-gray-200 pt-8">
                <h2 className="text-2xl font-semibold mb-6">Frequently Asked Questions</h2>
                <div className="space-y-4">
                  <details className="group bg-gray-50 rounded-lg">
                    <summary className="cursor-pointer p-4 font-medium">How do I use the emoji keyboard?</summary>
                    <div className="p-4 pt-0 text-gray-600">
                      Simply click any emoji to copy it instantly. You can also use the search bar to find specific emojis, or browse through categories. Your recently used emojis will appear at the top for quick access.
                    </div>
                  </details>
                  <details className="group bg-gray-50 rounded-lg">
                    <summary className="cursor-pointer p-4 font-medium">Do I need to install anything?</summary>
                    <div className="p-4 pt-0 text-gray-600">
                      No installation required! Our emoji keyboard works directly in your browser on any device. Just visit the page and start using emojis instantly.
                    </div>
                  </details>
                  <details className="group bg-gray-50 rounded-lg">
                    <summary className="cursor-pointer p-4 font-medium">Where can I use these emojis?</summary>
                    <div className="p-4 pt-0 text-gray-600">
                      Our emojis work everywhere - social media, messaging apps, emails, documents, and more. They're compatible with all major platforms including Instagram, Facebook, WhatsApp, Twitter, and TikTok.
                    </div>
                  </details>
                  <details className="group bg-gray-50 rounded-lg">
                    <summary className="cursor-pointer p-4 font-medium">Are these emojis free to use?</summary>
                    <div className="p-4 pt-0 text-gray-600">
                      Yes! All emojis are completely free to use for both personal and commercial purposes. No registration or subscription required.
                    </div>
                  </details>
                  <details className="group bg-gray-50 rounded-lg">
                    <summary className="cursor-pointer p-4 font-medium">How often are new emojis added?</summary>
                    <div className="p-4 pt-0 text-gray-600">
                      We regularly update our collection with the latest Unicode-approved emojis. Our keyboard is always up-to-date with the newest emoji releases.
                    </div>
                  </details>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmojiKeyboard;