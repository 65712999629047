import React from 'react';

const StarUsage = () => {
  return (
    <section className="bg-white rounded-2xl shadow-lg p-8 mb-8">
      <h2 className="text-2xl font-semibold mb-4">📱 Perfect Uses for Star Emojis</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <h3 className="text-lg font-medium mb-2">Achievement</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Success stories</li>
            <li>Awards & honors</li>
            <li>Perfect scores</li>
            <li>Recognition</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-2">Social Media</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Instagram posts</li>
            <li>Facebook updates</li>
            <li>Twitter messages</li>
            <li>TikTok captions</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-2">Reviews</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>5-star ratings</li>
            <li>Product reviews</li>
            <li>Service feedback</li>
            <li>Testimonials</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default StarUsage;