export const generateStarSchema = () => {
  const currentYear = new Date().getFullYear();
  
  return {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": `1000 Stars Generator [${currentYear}]`,
    "description": `Generate and copy 1000 star emojis ⭐. Updated for ${currentYear} with new designs. Perfect for expressing excellence and achievement!`,
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "1583",
      "bestRating": "5",
      "worstRating": "1"
    }
  };
};