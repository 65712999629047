import React, { useState } from 'react';
import { ArrowLeft, Copy, Check, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NewEmoji = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const newEmojis = {
    faces: ['🥹', '🫣', '🫢', '🫡', '🫥', '🫤'],
    handGestures: ['🫰', '🫱', '🫲', '🫳', '🫴', '🫷', '🫸'],
    people: ['🫅', '🫃', '🫄', '🧌'],
    animals: ['🦫', '🦬', '🦣', '🦭', '🪲', '🪳', '🪰', '🪱'],
    nature: ['🪴', '🫘', '🫗', '🫙', '🫕'],
    objects: ['🪭', '🪮', '🪯', '🛝', '🛟', '🪬', '🪩', '🪫'],
    symbols: ['⚧️', '🫶', '🏳️‍⚧️']
  };

  const handleCopy = (emoji: string) => {
    navigator.clipboard.writeText(emoji);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const title = `New Emojis ✨ Latest Collection [${currentYear}] 🆕`;
  const description = `【NEW RELEASE】 Get all new emojis ✨ Latest additions & designs 🆕 Updated for ${currentYear} 🔄 Copy & paste new emojis 💫 Complete collection of latest emoji releases!`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/new-emojis" />
        
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://emojicollection.net/new-emojis" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="new emojis, latest emojis, new emoji collection, emoji updates, latest emoji releases, new emoji designs, unicode emojis" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="Emoji Collection" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-purple-100 text-purple-500 mb-4">
                <Sparkles className="w-8 h-8" />
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                New Emojis ✨ Latest Collection [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Discover the latest emoji additions! Featuring new expressions, gestures, and symbols. Updated for {currentYear} with the most recent Unicode releases.
              </p>
            </header>

            {Object.entries(newEmojis).map(([category, emojis]) => (
              <section key={category} className="mb-12">
                <h2 className="text-2xl font-semibold mb-6 capitalize">{category}</h2>
                <div className="grid grid-cols-5 md:grid-cols-10 gap-4">
                  {emojis.map((emoji, index) => (
                    <div key={index} className="bg-gray-50 rounded-lg p-4">
                      <div className="text-center mb-2">
                        <span className="text-2xl">{emoji}</span>
                      </div>
                      <button
                        onClick={() => handleCopy(emoji)}
                        className={`w-full inline-flex items-center justify-center px-2 py-1 rounded text-xs font-medium ${
                          copied ? 'bg-green-500 text-white' : 'bg-purple-500 text-white hover:bg-purple-600'
                        } transition-colors`}
                      >
                        {copied ? (
                          <>
                            <Check className="mr-1" size={12} />
                            Copied
                          </>
                        ) : (
                          <>
                            <Copy className="mr-1" size={12} />
                            Copy
                          </>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </section>
            ))}

            <section className="prose prose-lg max-w-none">
              <h2>About New Emojis</h2>
              <p>
                The emoji collection is regularly updated with new additions that reflect our changing world and communication needs. These updates include:
              </p>
              <ul>
                <li>New facial expressions and emotions</li>
                <li>Additional hand gestures and body language</li>
                <li>More inclusive people representations</li>
                <li>New animals and nature symbols</li>
                <li>Modern objects and activities</li>
                <li>Cultural and social symbols</li>
              </ul>

              <h2>Platform Support</h2>
              <p>
                New emojis are implemented across major platforms:
              </p>
              <ul>
                <li>iOS and macOS devices</li>
                <li>Android phones and tablets</li>
                <li>Windows computers</li>
                <li>Major social media platforms</li>
                <li>Popular messaging apps</li>
              </ul>

              <h2>Usage Guidelines</h2>
              <p>
                To make the most of new emojis:
              </p>
              <ul>
                <li>Update your devices regularly</li>
                <li>Check platform compatibility</li>
                <li>Consider context and audience</li>
                <li>Combine with existing emojis</li>
                <li>Stay informed about new releases</li>
              </ul>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">What are the newest emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    The newest emojis include various additions like new facial expressions, hand gestures, people, animals, and objects. These are regularly updated through Unicode standards and implemented across platforms.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">When are new emojis released?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    New emojis are typically released annually through Unicode updates. Major platforms like Apple, Google, and Samsung then implement these new emojis in their systems through software updates.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">How can I get the new emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    To access new emojis, make sure your device's operating system is up to date. Most new emojis become available through regular system updates on your phone, tablet, or computer.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default NewEmoji;