import React, { useState } from 'react';
import { ArrowLeft, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const ZeroEmoji = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const zeroEmoji = '0️⃣';

  const handleCopy = () => {
    navigator.clipboard.writeText(zeroEmoji);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const title = `Zero Emoji 0️⃣ Copy and Paste [${currentYear}] | #1 Number Emoji`;
  const description = `【INSTANT COPY】 Get the zero number emoji 0️⃣ One-click copy & paste ⚡ Works everywhere! Updated for ${currentYear} ✨ Free number emoji for Instagram, WhatsApp & more!`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/emoji/zero" />
        
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://emojicollection.net/emoji/zero" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        
        <meta name="keywords" content="zero emoji, 0 emoji, number emoji, keycap zero, zero copy paste, number symbols, digital numbers, emoji numbers" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="Emoji Collection" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 text-blue-500 mb-4">
                <span className="text-2xl">0️⃣</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                Zero Emoji 0️⃣ Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to the zero emoji (0️⃣)! Perfect for numbers, scoring, and digital communication. Updated for {currentYear} with the latest design. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gray-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  0️⃣ Copy Zero Emoji Instantly
                </h2>
                <div className="mb-4 text-center">
                  <span className="text-6xl">{zeroEmoji}</span>
                </div>
                <button
                  onClick={handleCopy}
                  className={`w-full inline-flex items-center justify-center px-6 py-3 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-blue-500 to-indigo-500 text-white hover:from-blue-600 hover:to-indigo-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={20} />
                      Copied Zero Emoji!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={20} />
                      Copy Zero Emoji
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">✨ Features & Benefits</h2>
              <div className="text-gray-600 space-y-4">
                <p>0️⃣ Official zero number emoji</p>
                <p>📋 Instant one-click copy functionality</p>
                <p>💫 Works on all platforms and devices</p>
                <p>🆕 Updated for {currentYear} with latest design</p>
                <p>🔄 Perfect for digital communication</p>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">📱 Perfect For</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-2">Numbers & Scoring</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Scorekeeping</li>
                    <li>Counting</li>
                    <li>Rankings</li>
                    <li>Statistics</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Messaging</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>WhatsApp</li>
                    <li>Messenger</li>
                    <li>iMessage</li>
                    <li>SMS</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">What is the zero emoji?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    The zero emoji (0️⃣) is a keycap number symbol representing the digit zero. It's commonly used in digital communication for numbers, scoring, and counting.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Does this emoji work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! The zero emoji is universal and works on all major platforms including Instagram, Facebook, WhatsApp, and more. It's compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Is this emoji free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! The zero emoji is completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default ZeroEmoji;