import React, { useState } from 'react';
import { ArrowLeft, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Santa100 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const santa = '🎅'.repeat(100);

  const handleCopy = () => {
    navigator.clipboard.writeText(santa);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const title = `100 Santa Emojis 🎅 Copy and Paste [${currentYear}]`;
  const description = `【INSTANT COPY】 Get 100 Santa emojis! 🎅 Perfect for Christmas and holiday messages 🎅 One-click copy & paste 🎅 Works everywhere! Updated for ${currentYear} 🎅 Free Santa emojis for Instagram, WhatsApp & more!`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/emoji/santa-100" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://emojicollection.net/emoji/santa-100" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="keywords" content="100 santa emojis, santa emoji, christmas emoji, santa claus emoji, holiday emojis, christmas copy paste, instagram santa, whatsapp santa" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100 text-red-500 mb-4">
                <span className="text-2xl">🎅</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                100 Santa Emojis 🎅 Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to 100 Santa Claus emojis! Perfect for Christmas messages, holiday greetings, and festive social media posts. Updated for {currentYear} with the latest emojis. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gray-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  🎅 Copy 100 Santa Emojis Instantly
                </h2>
                <div className="mb-4 overflow-auto max-h-96 text-left break-words text-2xl">
                  {santa}
                </div>
                <button
                  onClick={handleCopy}
                  className={`inline-flex items-center px-6 py-3 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-red-500 to-green-500 text-white hover:from-red-600 hover:to-green-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={20} />
                      Copied 100 Santas!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={20} />
                      Copy 100 Santas
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">✨ Features & Benefits</h2>
              <div className="text-gray-600 space-y-4">
                <p>🎅 100 festive Santa emojis</p>
                <p>🎄 Perfect for holiday messages</p>
                <p>📋 Instant one-click copy functionality</p>
                <p>💫 Works on all platforms and devices</p>
                <p>🆕 Updated for {currentYear} with latest emojis</p>
                <p>🔄 Ready to spread holiday cheer</p>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">📱 Perfect For</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-2">Holiday Greetings</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Christmas cards</li>
                    <li>Holiday messages</li>
                    <li>Season's greetings</li>
                    <li>Party invitations</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Messaging</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>WhatsApp</li>
                    <li>Messenger</li>
                    <li>iMessage</li>
                    <li>SMS</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why use Santa emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Santa emojis add festive cheer to your holiday messages and social media posts. They're perfect for spreading Christmas spirit and creating memorable holiday greetings.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these emojis work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These Santa emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Are these emojis free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! Our Santa emojis are completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default Santa100;