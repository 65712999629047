import React, { useState } from 'react';
import { ArrowLeft, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Clap1000 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const claps = '👏'.repeat(1000);

  const handleCopy = () => {
    navigator.clipboard.writeText(claps);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const title = "1000 Clap Emojis 👏 Copy and Paste [" + currentYear + "] Free Collection";
  const description = "【INSTANT COPY】 Get 1000 clap emojis! 👏 Perfect for showing appreciation & support 👏 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear + " ✨ Free clap emojis for Instagram, WhatsApp & more!";
  const ogTitle = "1000 Clap Emojis 👏 Copy and Paste [" + currentYear + "] Free Collection";
  const ogDescription = "Get 1000 clap emojis! 👏 Perfect for showing appreciation & support 👏 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear;
  const twitterTitle = "1000 Clap Emojis 👏 Copy and Paste [" + currentYear + "] Free Collection";
  const twitterDescription = "Get 1000 clap emojis! 👏 Perfect for showing appreciation & support 👏 One-click copy & paste ⚡ Works everywhere! Updated for " + currentYear;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/emoji/clap-1000" />
        
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:url" content="https://emojicollection.net/emoji/clap-1000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="1000 clap emojis, clap emoji copy paste, applause emoji, appreciation emoji, support emoji, instagram claps, whatsapp claps" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="Emoji Collection" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <img 
                src="https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?auto=format&fit=crop&w=1200&q=80"
                alt="Show appreciation with clap emojis"
                className="w-full h-48 object-cover rounded-xl mb-8"
              />
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-yellow-500 to-orange-500 text-white mb-4">
                <span className="text-2xl">👏</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                1000 Clap Emojis 👏 Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to 1000 clap emojis! Perfect for showing appreciation, support, and celebration. Each clap is carefully selected to help you express your enthusiasm. Updated for {currentYear} with the latest emojis. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gradient-to-r from-yellow-50 to-orange-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                  <span className="text-2xl mr-2">👏</span>
                  Copy 1000 Claps Instantly
                </h2>
                <div className="mb-6 overflow-auto max-h-96 text-left break-words text-2xl bg-white p-6 rounded-lg shadow-inner">
                  {claps}
                </div>
                <button
                  onClick={handleCopy}
                  className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-yellow-500 to-orange-500 text-white hover:from-yellow-600 hover:to-orange-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={24} />
                      Copied 1000 Claps!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={24} />
                      Copy 1000 Claps
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">✨ Clap Emoji Uses & Meanings</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-4">Expression Types</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">👏 Single Clap - Basic appreciation</li>
                    <li className="flex items-center">👏👏 Double Clap - Increased enthusiasm</li>
                    <li className="flex items-center">👏👏👏 Triple Clap - Strong approval</li>
                    <li className="flex items-center">👏🎉 Clap & Celebration - Special achievements</li>
                    <li className="flex items-center">👏❤️ Clap & Heart - Loving support</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-4">Features</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">✨ 1000 expressive claps</li>
                    <li className="flex items-center">🌟 Perfect for appreciation</li>
                    <li className="flex items-center">📱 Works on all devices</li>
                    <li className="flex items-center">🔄 Fresh patterns</li>
                    <li className="flex items-center">📋 One-click copy feature</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">📱 Perfect Uses for 1000 Claps</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-2">Appreciation</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Achievements</li>
                    <li>Performances</li>
                    <li>Success stories</li>
                    <li>Celebrations</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Support</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Encouragement</li>
                    <li>Recognition</li>
                    <li>Congratulations</li>
                    <li>Team spirit</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why use clap emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Clap emojis are perfect for showing appreciation, support, and celebration. They add enthusiasm and positive energy to your messages and social media posts.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these claps work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These clap emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Are these claps free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! Our clap emojis are completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default Clap1000;