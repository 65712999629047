import React, { useState } from 'react';
import { ArrowLeft, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Happy1000 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const happyEmojis = [
    '😊', '😃', '😄', '😁', '😆', '🥰', '😍', '🤗', '🥳', '😎'
  ];

  const happyMessage = Array(1000)
    .fill(null)
    .map(() => happyEmojis[Math.floor(Math.random() * happyEmojis.length)])
    .join('');

  const handleCopy = () => {
    navigator.clipboard.writeText(happyMessage);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Helmet>
        <title>1000 Happy Emojis 😊 Copy and Paste [{currentYear}] 😃</title>
        <meta name="description" content={`【INSTANT COPY】 Get 1000 happy emojis! 😊 Perfect mix of joyful expressions 😃 One-click copy & paste 😄 Works everywhere! Updated for ${currentYear} 🥰 Free happy emojis for Instagram, WhatsApp & more!`} />
        <link rel="canonical" href="https://emojicollection.net/emoji/happy-1000" />
        
        <meta property="og:title" content={`1000 Happy Emojis 😊 Copy and Paste [${currentYear}] 😃`} />
        <meta property="og:description" content={`Get 1000 happy emojis! 😊 Perfect mix of joyful expressions 😃 One-click copy & paste 😄 Works everywhere! Updated for ${currentYear}`} />
        <meta property="og:url" content="https://emojicollection.net/emoji/happy-1000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1531686264889-56fdcabd163f?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`1000 Happy Emojis 😊 Copy and Paste [${currentYear}] 😃`} />
        <meta name="twitter:description" content={`Get 1000 happy emojis! 😊 Perfect mix of joyful expressions 😃 One-click copy & paste 😄 Works everywhere! Updated for ${currentYear}`} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1531686264889-56fdcabd163f?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="1000 happy emojis, happy emoji copy paste, happy face emojis, smile emojis, joyful emojis, instagram happy faces, whatsapp happy emojis" />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <img 
                src="https://images.unsplash.com/photo-1531686264889-56fdcabd163f?auto=format&fit=crop&w=1200&q=80"
                alt="Express joy with happy face emojis"
                className="w-full h-48 object-cover rounded-xl mb-8"
              />
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-yellow-400 to-orange-500 text-white mb-4">
                <span className="text-2xl">😊</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                1000 Happy Emojis 😊 Copy and Paste [{currentYear}]
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get instant access to 1000 happy and joyful emojis! Perfect mix of smiles, grins, and happy expressions. Updated for {currentYear} with the latest designs. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gradient-to-r from-yellow-50 to-orange-50 rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  😊 Copy 1000 Happy Emojis Instantly
                </h2>
                <div className="mb-4 overflow-auto max-h-96 text-left break-words text-2xl">
                  {happyMessage}
                </div>
                <button
                  onClick={handleCopy}
                  className={`inline-flex items-center px-6 py-3 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-yellow-400 to-orange-500 text-white hover:from-yellow-500 hover:to-orange-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={20} />
                      Copied 1000 Happy Emojis!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={20} />
                      Copy 1000 Happy Emojis
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">✨ Happy Emoji Types & Meanings</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-4">Emoji Variations</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">😊 Smiling Face - Warmth & joy</li>
                    <li className="flex items-center">😃 Grinning Face - Excitement</li>
                    <li className="flex items-center">😄 Grinning with Eyes - Pure happiness</li>
                    <li className="flex items-center">😁 Beaming Face - Big smile</li>
                    <li className="flex items-center">🥰 Smiling with Hearts - Love & joy</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-4">Features</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">✨ 1000 joyful emojis</li>
                    <li className="flex items-center">🌈 Perfect emoji mix</li>
                    <li className="flex items-center">📱 Works on all devices</li>
                    <li className="flex items-center">🔄 Random selection</li>
                    <li className="flex items-center">📋 One-click copy feature</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">📱 Perfect For</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-2">Happy Moments</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Celebrations</li>
                    <li>Good news</li>
                    <li>Achievements</li>
                    <li>Special events</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Messaging</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>WhatsApp</li>
                    <li>Messenger</li>
                    <li>iMessage</li>
                    <li>SMS</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-4">❓ Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why use happy emojis?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Happy emojis help express joy and positive emotions in digital communication. They add warmth and enthusiasm to your messages and social media posts.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these emojis work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These happy emojis are universal and work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Are these emojis free to use?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Absolutely! Our happy emojis are completely free to use for both personal and commercial purposes. No attribution required.
                  </div>
                </details>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default Happy1000;