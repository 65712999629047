import React from 'react';

const StarFeatures = () => {
  return (
    <section className="bg-white rounded-2xl shadow-lg p-8 mb-8">
      <h2 className="text-2xl font-semibold mb-4">✨ Star Emoji Uses & Meanings</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-medium mb-4">Expression Types</h3>
          <ul className="space-y-3 text-gray-600">
            <li className="flex items-center">⭐ Gold Star - Excellence & achievement</li>
            <li className="flex items-center">⭐⭐ Double Star - Outstanding performance</li>
            <li className="flex items-center">⭐⭐⭐ Triple Star - Perfect score</li>
            <li className="flex items-center">⭐✨ Star & Sparkles - Magic moments</li>
            <li className="flex items-center">⭐🌟 Star & Glow - Brilliant success</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-4">Features</h3>
          <ul className="space-y-3 text-gray-600">
            <li className="flex items-center">✨ 1000 bright stars</li>
            <li className="flex items-center">🌟 Perfect for recognition</li>
            <li className="flex items-center">📱 Works on all devices</li>
            <li className="flex items-center">🔄 Fresh patterns</li>
            <li className="flex items-center">📋 One-click copy feature</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default StarFeatures;