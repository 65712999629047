import React from 'react';
import { Helmet } from 'react-helmet-async';
import StarDisplay from './StarDisplay';
import StarFeatures from './StarFeatures';
import StarUsage from './StarUsage';
import StarFAQ from './StarFAQ';
import { generateStarSchema } from './schema';

const Star1000 = () => {
  const currentYear = new Date().getFullYear();
  const title = `1000 Star Emojis ⭐ Copy and Paste [${currentYear}] Free Collection`;
  const description = `【INSTANT COPY】 Get 1000 star emojis! ⭐ Perfect for expressing excellence & success ⭐ One-click copy & paste ⚡ Works everywhere! Updated for ${currentYear} ✨ Free star emojis for Instagram, WhatsApp & more!`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://emojicollection.net/emoji/star-1000" />
        
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://emojicollection.net/emoji/star-1000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="1000 star emojis, star emoji copy paste, gold star emoji, success emoji, achievement emoji, instagram stars, whatsapp stars" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="Emoji Collection" />
        <meta name="revisit-after" content="1 days" />
        
        <script type="application/ld+json">
          {JSON.stringify(generateStarSchema())}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <StarDisplay />
          <StarFeatures />
          <StarUsage />
          <StarFAQ />
        </div>
      </div>
    </>
  );
};

export default Star1000;