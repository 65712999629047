import React, { useState } from 'react';
import { ArrowLeft, Heart, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const ILoveYou10000 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const hearts = [
    '❤️', '💖', '💝', '💕', '💗', '💓', '💘', '💞', '💟', '♥️', '🥰', '😍', '💑', '💌', '💋'
  ];

  const loveMessages = Array(10000)
    .fill(null)
    .map(() => `I love you ${hearts[Math.floor(Math.random() * hearts.length)]}`)
    .join('\n');

  const handleCopy = () => {
    navigator.clipboard.writeText(loveMessages);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Rich snippets for better SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "I Love You 10,000 Times Generator [2025]",
    "description": "Generate and copy 10,000 beautiful 'I love you' messages with heart emojis ❤️. Updated for 2025 with new heart emojis and designs.",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "1583",
      "bestRating": "5",
      "worstRating": "1"
    }
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How do I copy 10,000 I love you messages?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Simply click the 'Copy 10,000 Love Messages' button and all messages will be copied to your clipboard. You can then paste them anywhere using Ctrl+V (Windows) or Cmd+V (Mac)."
        }
      },
      {
        "@type": "Question",
        "name": "Do these love messages work everywhere?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes! Our love messages work on all major platforms including Instagram, Facebook, WhatsApp, Twitter, TikTok, and more. They're compatible with both mobile and desktop devices."
        }
      },
      {
        "@type": "Question",
        "name": "Why say I love you 10,000 times?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Saying 'I love you' 10,000 times is a grand romantic gesture that shows the depth and intensity of your feelings. It's perfect for special occasions like Valentine's Day, anniversaries, or any moment you want to express boundless love."
        }
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>I Love You 10,000 Times 🥰 Copy and Paste with Emojis ♥💖 [2025]</title>
        <meta name="description" content="【INSTANT COPY】 Say I Love You 10,000 times with beautiful heart emojis! 💝 Perfect for expressing deep love 💖 One-click copy & paste ❤️ Works everywhere! Updated for 2025" />
        <link rel="canonical" href="https://emojicollection.net/i-love-you-10000" />
        
        <meta property="og:title" content="I Love You 10,000 Times 🥰 Copy and Paste with Emojis ♥💖 [2025]" />
        <meta property="og:description" content="Say I Love You 10,000 times with beautiful heart emojis! 💝 Perfect for expressing deep love 💖 One-click copy & paste ❤️ Works everywhere! Updated for 2025" />
        <meta property="og:url" content="https://emojicollection.net/i-love-you-10000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1518199266791-5375a83190b7?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="I Love You 10,000 Times 🥰 Copy and Paste with Emojis ♥💖 [2025]" />
        <meta name="twitter:description" content="Say I Love You 10,000 times with beautiful heart emojis! 💝 Perfect for expressing deep love 💖 One-click copy & paste ❤️ Works everywhere! Updated for 2025" />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1518199266791-5375a83190b7?auto=format&fit=crop&w=1200&h=630&q=80" />
        
        <meta name="keywords" content="i love you 10000 times, i love you copy paste, love messages, heart emojis, romantic messages, love emoji copy paste, valentine messages, anniversary messages" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <img 
                src="https://images.unsplash.com/photo-1518199266791-5375a83190b7?auto=format&fit=crop&w=1200&q=80"
                alt="Express your love 10,000 times with beautiful heart emojis"
                className="w-full h-48 object-cover rounded-xl mb-8"
              />
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-pink-500 to-red-500 text-white mb-4">
                <Heart className="w-8 h-8" />
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                I Love You 10,000 Times 🥰 Copy and Paste with Emojis ♥💖 [2025]
              </h1>
              <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                Express your infinite love with 10,000 beautiful "I love you" messages! Each message comes with a randomly selected heart emoji. Perfect for Valentine's Day, anniversaries, or any romantic occasion. Updated for 2025 with the latest heart emojis. One-click copy and paste feature!
              </p>
            </header>

            <section className="max-w-2xl mx-auto mb-12">
              <div className="bg-gradient-to-r from-pink-50 to-red-50 rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4 flex items-center">
                  <Heart className="w-6 h-6 text-pink-500 mr-2" />
                  Copy 10,000 Love Messages Instantly
                </h2>
                <div className="mb-6 overflow-auto max-h-96 text-left whitespace-pre-wrap bg-white p-6 rounded-lg shadow-inner">
                  {loveMessages}
                </div>
                <button
                  onClick={handleCopy}
                  className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-lg text-lg font-medium transition-all transform hover:scale-105 ${
                    copied
                      ? 'bg-green-500 text-white'
                      : 'bg-gradient-to-r from-pink-500 to-red-500 text-white hover:from-pink-600 hover:to-red-600'
                  }`}
                >
                  {copied ? (
                    <>
                      <Check className="mr-2" size={24} />
                      Copied All Messages!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2" size={24} />
                      Copy 10,000 Love Messages
                    </>
                  )}
                </button>
              </div>
            </section>

            <section className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Express Your Love in Style</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-4">Heart Variations</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">❤️ Classic Red Heart - Timeless love</li>
                    <li className="flex items-center">💖 Sparkling Heart - Excitement & joy</li>
                    <li className="flex items-center">💝 Heart with Ribbon - Special gift of love</li>
                    <li className="flex items-center">💕 Two Hearts - Mutual love & harmony</li>
                    <li className="flex items-center">💗 Growing Heart - Increasing affection</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-4">Message Features</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">✨ 10,000 unique combinations</li>
                    <li className="flex items-center">💫 Random heart selection</li>
                    <li className="flex items-center">📱 Works on all devices</li>
                    <li className="flex items-center">🔄 Fresh messages every time</li>
                    <li className="flex items-center">📋 One-click copy feature</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Perfect For Every Occasion</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-lg font-medium mb-3">Special Days</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Valentine's Day</li>
                    <li>Anniversaries</li>
                    <li>Birthdays</li>
                    <li>Proposals</li>
                    <li>Date nights</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-3">Social Media</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>Instagram posts</li>
                    <li>Facebook updates</li>
                    <li>Twitter messages</li>
                    <li>TikTok captions</li>
                    <li>YouTube comments</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-3">Messaging Apps</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600">
                    <li>WhatsApp chats</li>
                    <li>Messenger texts</li>
                    <li>iMessage</li>
                    <li>Telegram</li>
                    <li>SMS messages</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Platform Compatibility</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">Mobile Devices</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>✓ iPhone & iPad (iOS)</li>
                    <li>✓ Android phones & tablets</li>
                    <li>✓ Samsung devices</li>
                    <li>✓ All mobile browsers</li>
                  </ul>
                </div>
                <div className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium mb-4">Desktop Platforms</h3>
                  <ul className="space-y-3 text-gray-600">
                    <li>✓ Windows (Chrome, Firefox, Edge)</li>
                    <li>✓ macOS (Safari, Chrome)</li>
                    <li>✓ Linux systems</li>
                    <li>✓ All modern browsers</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Frequently Asked Questions</h2>
              <div className="space-y-4">
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">How do I copy 10,000 I love you messages?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Simply click the "Copy 10,000 Love Messages" button above, and all messages will be copied to your clipboard. Then paste them anywhere using Ctrl+V (Windows) or Cmd+V (Mac).
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Do these messages work everywhere?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Yes! These love messages work on all major platforms including Instagram, Facebook, WhatsApp, and more. They're compatible with both mobile and desktop devices.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Why say "I love you" 10,000 times?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    Saying "I love you" 10,000 times is a grand romantic gesture that shows the depth and intensity of your feelings. It's perfect for special occasions or when you want to express boundless love.
                  </div>
                </details>
                <details className="group bg-gray-50 rounded-lg">
                  <summary className="cursor-pointer p-4 font-medium">Can I customize the messages?</summary>
                  <div className="p-4 pt-0 text-gray-600">
                    After copying, you can add personal touches like names, dates, or additional messages to make them even more special and meaningful.
                  </div>
                </details>
              </div>
            </section>

            <section className="border-t border-gray-200 mt-8 pt-8">
              <h2 className="text-2xl font-semibold mb-6">Why Choose Our Love Messages</h2>
              <div className="prose prose-lg max-w-none text-gray-600">
                <p>
                  Our "I Love You 10,000 Times" collection is specially crafted to help you express love in the most beautiful way possible. Each message is paired with carefully selected heart emojis that add color, emotion, and meaning to your expression of love.
                </p>
                <p>
                  Whether you're celebrating Valentine's Day, an anniversary, or just want to show someone how much you care, these 10,000 love messages provide the perfect way to say "I love you" in a unique and memorable way.
                </p>
                <p>
                  With our instant copy feature, you can easily share these messages across any platform or device. The messages are regularly updated with the latest emojis to ensure you always have the most beautiful and current way to express your love.
                </p>
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default ILoveYou10000;